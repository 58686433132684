import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"ma-3",attrs:{"wrap":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:150'),expression:"'required|min:3|max:150'"}],ref:"descricao",attrs:{"label":"Descrição","placeholder":"Digite a descrição da pretensão salarial","dense":"","outlined":"","data-vv-as":"descrição","persistent-hint":"","hint":_vm.errors.first('res_pretensao_salarial.descricao'),"error":_vm.errors.collect('res_pretensao_salarial.descricao').length
            ? true
            : false,"data-vv-scope":"res_pretensao_salarial","data-vv-validate-on":"change","id":"descricao","name":"descricao"},model:{value:(_vm.pretensaoSalarial.descricao),callback:function ($$v) {_vm.$set(_vm.pretensaoSalarial, "descricao", $$v)},expression:"pretensaoSalarial.descricao"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }