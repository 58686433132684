import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"name":"pretensoesSalariais"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('Crud',{attrs:{"title":"Gestão de Pretensões salariais","buttonAddItemText":"Nova pretensão salarial","searchLabelText":"Pesquisar na lista","searchTextField":_vm.searchTextField,"formTitle":_vm.nameForm,"callBackModalCancelar":_vm.fechar,"dialog":_vm.dialog,"callBackModalSalvar":_vm.salvar,"dialogDelete":_vm.dialogDelete},on:{"update:searchTextField":function($event){_vm.searchTextField=$event},"update:search-text-field":function($event){_vm.searchTextField=$event},"update:dialog":function($event){_vm.dialog=$event},"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"data-content"},[_c(VRow,{staticClass:"mt-5"})],1),_c('template',{slot:"data-table"},[_c(VDataTable,{staticClass:"elevation-5",attrs:{"headers":_vm.headers.filter(function (header) {
                return header.value != 'editar' && header.value != 'acao';
              }),"items":_vm.pretensoesSalariais,"loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.toggleModalLateral(item)}}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.remover(item, true)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1)],1)]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.created_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.created_at))+"h")])]}}],null,true)})],1),_c('template',{slot:"modalDelete/titleModal"},[_vm._v(" Excluir o registro? ")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadingButtonDelete,"color":"error"},on:{"click":function($event){return _vm.remover()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1),_c('template',{slot:"contentModalCrud"},[_c('PretensoesSalariaisForm')],1)],2)],1)],1),_c('ModalLateral',{attrs:{"show":_vm.modalLateral.show,"hide":_vm.toggleModalLateral,"title":_vm.modalLateral.title,"headers":_vm.headerModalLateral,"item":_vm.modalLateral.item},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.created_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.created_at))+"h")])]}},{key:"item.updated_at",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.updated_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.updated_at))+"h")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }